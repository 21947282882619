body,
html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background: rgb(24, 176, 226);
  background: radial-gradient(
    circle,
    rgb(87, 213, 255) 0%,
    rgb(0, 147, 196) 99%,
    rgba(28, 199, 255, 1) 99%
  );
  color: #333;
  margin: 0px;
}

.logo {
  position: relative;
  width: 70vw;
  max-width: 300px;
  left: 50%;
  transform: translate(-50%);
  margin-top: 3vh;
  margin-bottom: -2em;
}

.title {
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  text-align: center;
  margin: 0px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.4rem;
}

.title2 {
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  text-align: center;
  margin: 0px;
  margin-bottom: 2em;
  font-size: 1.4rem;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.center {
  /* position: absolute; */
  z-index: 10;
  font-family: "Red Hat Display", sans-serif;
}

.top3 {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #4b4168;
}
.top3 .item {
  box-sizing: border-box;
  position: relative;
  background: white;
  width: 9rem;
  height: 10rem;
  text-align: center;
  padding: 2.8rem 0 0;
  margin: 1rem 1rem 2rem;
  border-radius: 0.5rem;
  transform-origin: bottom;
  cursor: pointer;
  transition: transform 200ms ease-in-out;
  box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1),
    0 1rem 2rem -1rem rgba(0, 0, 0, 0.3);
}
.top3 .item .pic {
  margin: 0px;
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translate(-50%);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2),
    0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
  /* margin-right: 1rem; */
}
.top3 .item .pos {
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.top3 .item .name {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.top3 .item .score {
  opacity: 0.5;
}
.top3 .item .score:after {
  display: block;
  content: " avaliações";
  opacity: 0.5;
}
.top3 .item.one {
  width: 10rem;
  height: 11rem;
  padding-top: 3.5rem;
}
.top3 .item.one .pic {
  width: 5rem;
  height: 5rem;
}
.top3 .item:hover {
  z-index: 20;
  outline: none !important;
  transform: scale(1.05);
}

/* 









List 













*/

.list {
  padding-left: 2rem;
  margin: 0;
}
.list .item {
  left: 50%;
  transform: translate(-50%);
  position: relative;
  display: flex;
  justify-content: center;
  width: 85vw;
  max-width: 600px;
  align-items: center;
  background: white;
  height: 3rem;
  border-radius: 4rem;
  margin-bottom: 2rem;
  /* background: #ffbc56; */
  background: rgb(255, 201, 74);
  transform-origin: left;
  cursor: pointer;
  transition: 200ms ease;
  box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.1),
    0 1rem 2rem -1rem rgba(0, 0, 0, 0.3);
}
.list .item .pos {
  font-weight: 900;
  position: absolute;
  left: -2rem;
  text-align: center;
  font-size: 1.25rem;
  width: 1.5rem;
  color: white;
  opacity: 0.6;
  transition: opacity 200ms ease-in-out;
}
.list .item .pic {
  width: 4rem;
  height: 4rem;
  min-width: 70px;
  min-height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-right: 1rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2),
    0 1rem 1rem -0.5rem rgba(0, 0, 0, 0.3);
}
.list .item .name {
  flex-grow: 2;
  flex-basis: 5rem;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.904);
  font-weight: bold;
}
.list .item .score {
  margin-right: 1.5rem;
  opacity: 0.5;
  color: #000;
}
.list .item .score:after {
  margin-right: 0.5rem;
  content: "avaliações";
  opacity: 0.5;
  position: relative;
  left: 10px;
}
.list .item:hover {
  transform: translate(-50%) scale(1.05);
}
.list .item:hover .pos {
  opacity: 0.8;
}

.medalOne {
  width: 50px;
  position: absolute;
  right: 0;
  top: 0;
}

/* 













Responsive code














*/

@media only screen and (max-width: 700px) {
  .logo {
    position: relative;
    width: 70vw;
    max-width: 200px;
    left: 50%;
    transform: translate(-50%);
    margin-top: 3vh;
    margin-bottom: -2em;
  }

  .top3 {
    position: relative;
    top: 45vh;
  }

  .top3 .item {
    margin: 0px;
  }

  .top3 .item.two {
    z-index: 3;
    position: absolute;
    left: 10px;
    top: -250px;
    transition: 0.3s ease;
  }

  .top3 .item.two:hover {
    position: absolute;
    left: 10vw;
    z-index: 20;
    top: -250px;
    transform: scale(1.3);
  }

  .top3 .item.three {
    position: absolute;
    top: -110px;
    right: 10px;
    z-index: 1;
    transition: 0.3s ease;
  }

  .top3 .item.three:hover {
    position: absolute;
    right: 10vw;
    z-index: 20;
    transform: scale(1.3);
  }

  .top3 .item.one {
    outline: none;
    position: absolute;
    top: -200px;
    width: 11rem;
    height: 11rem;
    padding-top: 3.5rem;
    z-index: 3;
    left: 27vw;
    transition: 0.3s ease;
  }

  .top3 .item.one:hover {
    position: absolute;
    transform: scale(1.3);
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 0% rgba(247, 255, 38, 1) 100%
    );
    z-index: 10;
  }

  .list {
    position: relative;
    top: 68vh;
    margin-bottom: 10em;
  }
}


@media only screen and (max-height: 500px) {

  .logo {
    position: relative;
    width: 70vw;
    max-width: 200px;
    left: 50%;
    transform: translate(-50%);
    margin-top: 3vh;
    margin-bottom: 2em;
  }

}